
import {parseDate, parseTitle,parseDescription} from './Article';
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export function formatToShortDate(date) {
    if (!(date instanceof Date)) {
        throw new Error("Input must be a valid Date object");
    }
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);

}

function formatToShorterDate(date) {
    if (!(date instanceof Date)) {
        throw new Error("Input must be a valid Date object");
    }
    const options = { month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
}

export const Blog = ({articles}) => {
    articles = articles.sort((a, b) => b.date - a.date);
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 5;
    const totalPages = Math.ceil(articles.length / articlesPerPage);

    const isNext = () => {
        return currentPage < totalPages;
    };

    const isPrev = () => {
        return currentPage > 1;
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const startIndex = (currentPage - 1) * articlesPerPage;
    const currentArticles = articles.slice(startIndex, startIndex + articlesPerPage);
    return (
        <div style={{paddingBottom:100}}>
            <Helmet>
                <title>Thomas Crook - Blog</title>
                <meta name="description" content={"Blog posts about self-improvement, software development, and anything that interests me."}/>
            </Helmet>
            <section>
                <h1 style={{paddingBottom:20}}>Blog Posts</h1>
                {
                    articles.length <= 0 &&
                    <p>Wait till my first post!</p>
                }
                {
                    currentArticles.map((article) =>
                        <Link to={article.href}>
                        <span
                        style={{cursor:'pointer', display:'block', transition: 'background-color 0.3s', padding:'20px 10px 20px 10px', borderBottom: '1px solid #434549'}} 
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#434549'} 
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                        <h2 style={{
                                margin:0,
                                color:'white', 
                                fontSize:20,
                                display: '-webkit-box', 
                                WebkitBoxOrient: 'vertical', 
                                WebkitLineClamp: 3, 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis' 
                                }}
                            >{parseTitle(article)}
                            </h2>
                        <time 
                        style={{
                            fontSize:15,
                            margin:0
                        }}
                        >
                            {formatToShortDate(parseDate(article))}
                        </time>
                        <p style={{ 
                            display: '-webkit-box', 
                            WebkitBoxOrient: 'vertical', 
                            WebkitLineClamp: 4, 
                            overflow: 'hidden', 
                            textOverflow: 'ellipsis',
                            fontSize:14
                            }}
                            >
                            {parseDescription(article)}
                        </p>
                    </span>
                    </Link>
                    )
                }
            </section>
            <button 
                onClick={() => handlePreviousPage()} 
                style={{
                    fontFamily: 'Consolas, serif',
                    background: 'none',
                    color: 'lightgray',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '10px',
                    fontSize: '16px',
                    display: isPrev() ? 'block' : 'none'
                }}
                onMouseEnter={(e) => e.currentTarget.style.color = 'white'}
                onMouseLeave={(e) => e.currentTarget.style.color = 'lightgray'}
            >
                Previous
            </button>
            <button 
                onClick={() => handleNextPage()} 
                style={{
                    fontFamily: 'Consolas, serif',
                    background: 'none',
                    color: 'lightgray',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '10px',
                    fontSize: '16px',
                    display: isNext() ? 'block' : 'none'
                }}
                onMouseEnter={(e) => e.currentTarget.style.color = 'white'}
                onMouseLeave={(e) => e.currentTarget.style.color = 'lightgray'}
            >
                Next
            </button>
        </div>
    )
}