import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: scroll;
  }
  body {
    background-color: #323437;
    font-family: Consolas, monospace;
    color: ghostwhite;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    scrollbar-gutter: stable both-edges;
    line-height: 1.6;
  }

  /* Make scrollbar dimmer */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #323437;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    border: 3px solid #323437;
  }

  ul {
    padding: 15px 0 0 0;
    margin: 0;
    
  }

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }

  li time {
    font-size: 15px;
    color: darkgray;
    margin-right: 20px;
    white-space: nowrap;
  }

  li a {
    font-size: 15px;
    color: ghostwhite;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  li a:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  h1 a {
    color: lightgray;
    font-size: 25px;
  }

  a {
    color: darkgray;
    text-decoration: none;
    font-size: 18px;
  }

  a:hover {
    color: white;
  }

  section {
    padding: 25px 0;
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
  }


  h1 {
    font-size: 30px;
  }

  section h1 {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    /* small screens */
    body {
      padding: 10px 25px;
      font-size: 18px;
    }

    h1 {
      font-size: 25px;
    }

    h1 a {
      color: lightgray;
      font-size: 35px;
    }

    nav {
      width: 75%;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    /* tablets */
    body {
      padding: 20px;
      font-size: 20px;
    }

    h1 a {
      font-size: 40px;
    }

    a {
      font-size: 25px;
    }
  }

  @media (min-width: 1025px) {
    /* desktop */
    body {
      padding: 10px 250px;
      font-size: 16px;
    }

    h1 {
      font-size: 25px;
    }
  }
`;