
import { generateHref, parseDate, parseTitle } from './Article';
import {formatToShortDate} from './Blog';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export const Home = ({articles}) => {
    articles = articles.sort((a,b) => b.date - a.date);
    return (
        <div>
            <Helmet>
                <title>Thomas Crook - Home</title>
                <meta name="description" content="Welcome to my digital space, a personal blog where I share thoughts and ideas on a wide range of topics that captivate me."/>
            </Helmet>
            <section>
                <h1>Hello World!</h1>
                <p>Welcome to my digital space, a personal blog where I share thoughts and ideas on a wide range of topics that captivate me. From well-researched explorations to personal insights, this blog is a reflection of my curiosity and passion for growth.</p>
            </section>
            <section>
                <h1>Articles</h1>
                {
                    articles.length <= 0 &&
                    <p>Wait till my first post!</p>
                }
                {
                    articles.length > 0 &&
                    <ul>
                        {
                            articles.slice(0, 10).map((article) =>
                                
                                <Link to={`/blog/` + article.href}>
                                    <li>
                                        <time>{formatToShortDate(parseDate(article))}</time>
                                        <a>{parseTitle(article)}</a>
                                    </li>
                                </Link>)
                        }
                    </ul>
                }
            </section>
        </div>
        )
}