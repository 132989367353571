import {
    BrowserRouter as Router,
    Routes,
    Route, Outlet,
} from "react-router-dom";
import style from './App.module.css';
import ReactMarkdown from 'react-markdown';
import {Navbar} from "./Components/Navbar";
import {GlobalStyle} from "./GlobalStyle";
import {Home} from "./Components/Home";
import {About} from "./Components/About";
import {Blog} from "./Components/Blog";
import articles from './Articles.json';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { parseDescription } from "./Components/Article";

const articleElements = articles.map((article, index) => 
        <Route 
            key={index} 
            path={`/blog/` + article.href} 
            element={<ArticlePage article={article} />}/>);
  
function App() {

    return (
    <HelmetProvider>
        <GlobalStyle />
        <Router>
            <Routes>
                <Route path={'/'} element={<Layout />}>
                    <Route index element={<Home articles={articles}/>}/>
                    <Route path="/about" element={<About/>} />
                    <Route path="/blog" element={<Blog articles={articles}/>} />
                    {articleElements}
                </Route>
            </Routes>
        </Router>
    </HelmetProvider>
  );
}

function Layout() {
    return (
        <>
            <Navbar/>
            <Outlet /> 
            <div style={{paddingBottom:250}}/>
        </>
    );
}

function ArticlePage({ article }) {
    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://www.thomas-crook.com/blog/" + article.href} />
                <title>{article.title}</title>
                <meta name="description" content={parseDescription(article)} />
            </Helmet>
            <ReactMarkdown className={style.reactMarkdown}>{article.content}</ReactMarkdown>
        </>
    );
}

export default App;
