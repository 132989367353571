import styled from 'styled-components'
import { Link } from 'react-router-dom'
const NavDiv = styled.div`
  display:flex;
  justify-content: space-between;
  margin:auto;
  align-items: center;
  padding-bottom: 50px;

  @media (max-width: 768px) { /* small screens */
    width:100%;
    flex-direction: column;
  }
  @media (min-width: 769px) and (max-width: 1024px) { /* tablets */
    width:100%;
    flex-direction: column;
  }
  @media (min-width: 1025px) { /* desktop */
    
  }
`

export const Navbar = () => {
    return (
        <NavDiv>
            <Link 
            style={{
                fontSize: 25,
                fontWeight: 'bold',
                color: 'white',
                textDecoration: 'none',
                cursor: 'pointer',
                padding: 10                
            }}
            to="/">Thomas Crook</Link>
            <nav>
                <Link to={'/'}>Home</Link>
                <Link to={'/blog'}>Blog</Link>
                <Link to={'/about'}>About</Link>
            </nav>
        </NavDiv>)
}
