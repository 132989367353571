import ReactMarkdown from 'react-markdown';
import React from 'react';


export function parseTitle(article) {
    const lines = article.content.split('\n');
    return lines[0].replace('# ', '').trim();
}
export function parseDate(article) {
    try {
        const dateLine = article.content.split('\n').find(line => line.startsWith('**Date:**'));
        const dateString = dateLine ? dateLine.replace('**Date:** ', '').trim().replace('-', '/') : null;
        return dateString ? new Date(dateString): new Date();
    } catch (e) {
        return new Date()
    }
}
export function parseDescription(article) {
    let lines = article.content.split('\n');
    lines = lines.filter(line => !line.startsWith('#') && !line.startsWith('**'));
    const description = lines.join(' ').trim();
    const sentences = description.match(/[^\.!\?]+[\.!\?]+/g);
    return sentences ? sentences[0].trim() : '';
}

