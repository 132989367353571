
import { Helmet } from "react-helmet-async";
function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export const About = () => {
    return (
        <div>
            <Helmet>
                <title>Thomas Crook - About</title>
                <meta name="description" content={"About Thomas Crook - a " + calculateAge("2001-01-04") + " year old software developer."}/>
            </Helmet>

            <section>
                <h1>About</h1>
                <p>
                    I am Thomas Crook, a {calculateAge("2001-01-04")} year old British-American software developer & Northeastern graduate based in New York City.
                    My passions include programming (obviously), weightlifting, self-improvement, soccer, chess, and language learning.
                </p>
                <p>
                    Please bare in mind that I am not an expert on anything I discuss here.
                    I'm a young man driven by a curiosity to explore new ideas through writing and research.
                    This blog is my space to improve my front-end development skills, share my interests, and hone my writing. Hopefully it's a worthwhile endeavor.
                </p>
                <p>
                    Contact: <a  style={{fontSize:17}} href="mailto:tomcrook95@gmail.com">tomcrook95@gmail.com</a>
                </p>
            </section>
        </div>
    )
}